@import '../../../scss/theme-bootstrap';

.header-gnav-cart {
  .cart-confirm-wrapper {
    @include text-body-text--no-xl;
    background: $color-white;
    border: none;
    padding: 0;
    position: relative;
    &__bottom-section {
      // added as a span for 2019 Redesign
      background-color: $color-white;
      border-top: solid 1px $cr19-border-light;
      display: block;
      margin-bottom: 60px;
      padding-top: 12px;
      width: 100%;
    }
    a.close {
      background: none;
      width: 17px;
      height: 17px;
    }
    h2 {
      @include text-title--small;
      color: $cr19-text;
    }
    h2.cart-confirm-heading {
      background: transparent url('/media/images/global/nav/top/calendar-icon--dark.png') no-repeat scroll 0 5px;
      color: $cr19-text;
      font-family: $base-font-family-roman;
      font-size: 13px;
      letter-spacing: 0.03em;
      line-height: get-line-height(13px, 20px);
      margin: 0;
      padding-#{$ldirection}: 40px;
      p {
        line-height: get-line-height(13px, 20px);
      }
    }
    .cart-overlay-products {
      display: block;
      height: 400px;
      max-height: 400px;
      border-bottom: none;
      padding-bottom: 100px;
    }
    .cart-overlay-products-heading {
      @include text-body-text--no-xl;
      border-bottom-color: $cr19-border-light;
      text-transform: uppercase;
      padding-bottom: 2px;
      margin-top: 20px;
    }
    .cart-overlay-products-heading div {
      margin: 0;
      &.hright {
        padding-#{$rdirection}: 10px;
      }
      &.hleft {
        float: #{$ldirection};
      }
    }
    .cart-overlay-products-heading .hcenter {
      width: 260px;
    }
    .last-item-added {
      background-color: $cr19-bg-light;
    }
    .prod {
      align-items: center;
      border-bottom: solid 1px $cr19-border-light;
      display: flex;
      flex-direction: row;
      min-height: 90px; // keep from jumping while images load
      padding: 12px 0;
      margin: 0;
      width: 100%;
    }
    .cart-product-img {
      @include swap_direction(margin, 0 0 0 12px);
      color: $cr19-text;
      float: none;
      text-align: #{$ldirection};
      width: 120px;
      img {
        background-color: $cr19-bg-light;
        font-size: 0;
        height: auto;
        max-width: 80px;
        width: 80px;
      }
    }
    // shady
    .shade_info {
      align-items: center;
      display: flex;
      margin: 0;
      height: auto;
      padding-bottom: 6px;
      &.hidden {
        padding-bottom: 0;
        height: 0;
        display: none;
      }
    }
    .shade_thumb {
      @include swap_direction(margin, 0 6px 0 0);
      float: #{$ldirection};
      width: 20px;
      height: 20px;
      .shade_thumb_2 {
        width: 50%;
        height: 100%;
        float: #{$ldirection};
      }
      .shade_thumb_3 {
        width: 33%;
        height: 100%;
        float: #{$ldirection};
      }
      .shade_thumb_4 {
        width: 50%;
        height: 50%;
        float: #{$ldirection};
      }
    }
    .shadename {
      width: auto;
      font-size: 11px;
      line-height: 11px;
      height: auto;
      white-space: normal;
      text-align: #{$ldirection};
      display: inline-block;
      font-family: $base-font-family-roman;
    }
    .prod-info {
      display: block;
      float: none;
      width: 100%;
      .sample_color {
        color: $cr19-text;
      }
    }
    .cart-product-name {
      @include text-body-text--no-xl;
      color: $cr19-text;
      display: inline;
      float: #{$ldirection};
      margin: 0;
      max-width: 80%;
      padding: 0;
      width: auto;
      a {
        @include text-body-text--no-xl;
        @include text-link;
        @include text-link--style-2;
      }
    }
    .prod-details {
      @include swap_direction(padding, 0 12px 0 0);
      display: flex;
      float: #{$rdirection};
      &--full-width {
        width: 100%;
        justify-content: space-between;
        align-items: flex-end;
      }
    }
    .right-copy.qty-price {
      @include text-body-text--no-xl;
      margin-top: 0;
    }
    .qty {
      color: $cr19-text;
      margin-#{$rdirection}: 0;
      padding-#{$rdirection}: 12px;
    }
    .left-copy {
      @include text-body-text--no-xl;
      color: $cr19-text;
    }
    .right-copy {
      float: #{$rdirection};
      text-align: #{$rdirection};
    }
    .strike-thru-price {
      opacity: 0.5;
      margin-#{$rdirection}: 15px;
      text-decoration: line-through;
    }
    .items,
    .subtotal,
    .buttons,
    .total {
      @include text-body-text--no-xl;
      padding: 0;
    }
    .items {
      display: inline-block;
      float: #{$ldirection};
      font-family: $base-bolder-font-family;
      width: 50%;
    }
    .subtotal {
      display: inline-block;
      font-family: $base-bolder-font-family;
      width: 50%;
      .left-copy {
        float: #{$ldirection};
        margin: 0;
        text-align: #{$rdirection};
        width: 50%;
      }
      .right-copy {
        float: #{$rdirection};
      }
    }
    .total_div {
      width: 100%;
      padding-bottom: 10px;
      .last {
        color: $cr19-text;
        float: #{$rdirection};
        width: 130px;
        font-family: $base-bold-font-family;
        text-transform: uppercase;
      }
      .total {
        width: 90px;
        float: #{$rdirection};
        text-align: #{$rdirection};
      }
    }
    .buttons {
      border-top-color: $cr19-border-light;
      display: table;
      margin-top: 20px;
      padding: 12px 0 0 0;
      width: 100%;
      .left-copy {
        display: table-cell;
        width: 50%;
        a.button.button-secondary {
          @include elc-button;
          @include elc-button--light;
          padding: 0;
          margin: 0;
          border-color: 2px solid $cr19-border-light;
          font-size: 17px;
        }
      }
      .right-copy {
        display: table-cell;
        width: 50%;
        a.btn-primary {
          @include elc-button;
          @include elc-button--dark;
          padding: 0;
          margin: 0;
        }
      }
    }
  }
}
